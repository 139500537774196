<template>
    <static-layout title="Stream Rules">
        <div v-for="(section, key) in sections" :key="key">
            <div v-html="section.content" class="static-content"></div>
        </div>
    </static-layout>
</template>

<script>
import PageSection from "@/models/PageSection";
import StaticLayout from "@/layouts/StaticLayout";

export default {
    name: "StreamRules",
    components: {StaticLayout},
    data: function () {
        return {
            sections: [],
        }
    },
    methods: {
        fetchPageSections() {
            PageSection
                .where('page', 'streams')
                .get()
                .then(sections => this.sections = sections)
                .catch((e) => console.log(e))
        }
    },
    created() {
        this.fetchPageSections()
    }
}
</script>
